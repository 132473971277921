// eslint-disable-next-line
import { UserLayout, BasicLayout, VaiBasicLayout, LocationLayout, BlankLayout } from '@/layouts'
import { bxAnaalyse } from '@/core/icons'

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}

export const asyncRouterMap = [
  {
    path: '/admin',
    name: 'admin',
    component: BasicLayout,
    meta: { title: 'menu.home' },
    redirect: '/admin/rebate/items',
    children: [
      // dashboard
      {
        path: '/admin/rebate/items',
        name: 'rebateItem',
        component: () => import('@/vai/views/rebate/admin/RebateItems'),
        meta: { title: 'Rebates', keepAlive: false, icon: 'qrcode', permission: ['dashboard'] }
      },
      {
        path: '/admin/rebate/report',
        name: 'Report',
        component: () => import('@/vai/views/rebate/admin/RebateReport'),
        meta: { title: 'Report', keepAlive: false, icon: 'profile', permission: ['dashboard'] }
      },
      {
        path: '/admin/rebate/product',
        name: 'prouct',
        component: () => import('@/vai/views/rebate/admin/Products'),
        meta: { title: 'Product', keepAlive: true, icon: 'bars', permission: ['dashboard'] }
      },
      {
        path: '/admin/rebate/setting',
        name: 'setting',
        component: () => import('@/vai/views/rebate/admin/SettingTeamInfo'),
        meta: { title: 'Settings', keepAlive: true, icon: 'setting', permission: ['dashboard'] }
      },
      {
        path: '/admin/rebate/logout',
        name: 'logout',
        component: () => import('@/vai/views/rebate/admin/Logout'),
        meta: { title: 'Logout', keepAlive: true, icon: 'logout', permission: ['dashboard'] }
      }
    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const userRouterMap = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/vai/views/rebate/qrscan/RebateLayout'),
    meta: { title: 'menu.home' },
    redirect: '/rebate/qrscan',
    hidden: true,
    children: [
      {
        path: '/rebate/qrscan',
        name: 'qrscan',
        component: () => import('@/vai/views/rebate/qrscan'),
        meta: { title: 'QR Scan', keepAlive: false, icon: 'bank', permission: ['dashboard'] }
      },
      {
        path: '/exception/404',
        name: 'Exception404',
        component: () => import(/* webpackChunkName: "fail" */ '@/views/404'),
        meta: { title: 'menu.exception.not-find', permission: ['exception'] }
      }
    ]
  },
  {
    path: '/vuser',
    component: UserLayout,
    redirect: '/vuser/login',
    hidden: true,
    children: [
      {
        path: '/vuser/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/vai/views/user/Login')
      },
      {
        path: '/vuser/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/vai/views/user/Register')
      },
      {
        path: '/vuser/register-result',
        name: 'registerResult2',
        component: () => import(/* webpackChunkName: "user" */ '@/vai/views/user/RegisterResult')
      },
      {
        path: '/vuser/reset-password',
        name: 'resetPassword',
        component: () => import(/* webpackChunkName: "user" */ '@/vai/views/user/ResetPassword')
      },
      {
        path: '/vuser/reset-password-result',
        name: 'resetPasswordResult',
        component: () => import(/* webpackChunkName: "user" */ '@/vai/views/user/ResetPasswordResult')
      }
    ]
  }
]

export const vaiRouterMap = [
  {
    path: '/vai',
    component: VaiBasicLayout,
    meta: { title: 'Home' },
    redirect: '/vai/resetpassword',
    hidden: true,
    children: [
      {
        path: 'resetpassword',
        name: 'resetpassword',
        component: () => import('@/vai/views/user/ResetPassword')
      }
    ]
  }
]
